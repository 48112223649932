.control-round .carousel-control.left:hover, .control-round .carousel-control.right:hover {
  color: #fdfdfd;
  background: transparent;
  border: 1px solid #fdfdfd;
}

.slide-text {
  color: #fff;
  top: 15%;
}

.slide-text h1 {
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 500;
  font-size: 40px;
}

.slide-text p {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: uppercase;
}

.btn.btn-default.outline {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 20px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 2px;
  transition: all ease .5s;
  margin: 20px 0;
}

.btn.btn-default.outline {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.btn.btn-default.outline:hover, .btn.btn-default.outline:active, .btn.btn-default.outline:focus, .btn.btn-default.outline:active:focus {
  background-color: #076ED7;
  border-color: #076ED7;
  color: #fff;
}

.indicators-line > .carousel-indicators {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 90px;
}

/******** MAX ********/
@media (max-width: 1366px){
  .slide-text{
    top: 25%;
  }
}
@media (max-width:991px) {
  .slide-text{
    top: 35%;
    text-align: center!important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .slide-text h1{
    font-size: 30px;
  }
  .slide-text h2{
    font-size: 30px;
  }
  .slide-text p{
    font-size: 16px;
  }
}

.titulo h1 {
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  font-size: 20px;
  margin-top: 0;
}

.space {
  margin-top: 40px;
}

.titulo::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #ff8800;
}

.atributos p {
  text-align: center;
  margin-top: 20px;
  font-size: 13px;
}

.img-categoria img{
  transition: all .2s ease-in-out;
  z-index: 111;
}

.img-categoria img:hover{
  transform: scale(1.1);
}

.box-categoria h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
  color: #555;
}

.btn.square:hover, .btn.square:active, .btn.square:focus, .btn.square:active:focus {
  background-color: #076ED7;
  border-color: #076ED7;
  color: #fff;
}

.btn.square {
  transition: all ease .5s;
}